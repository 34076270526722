.fs10 {
    font-size: 10px;
}
.fs15 {
    font-size: 15px;
}
.fs20 {
    font-size: 20px;
}
.fs25 {
    font-size: 25px;
}
.fs30 {
    font-size: 30px;
}
.fs35 {
    font-size: 35px;
}
.fs40 {
    font-size: 40px;
}
.fs50 {
    font-size: 50px;
}

