.snotify {
    width: auto;
    max-width: 450px;

    .snotifyToast__progressBar {
        height: 7px;

        .snotifyToast__progressBar__percentage {
            height: 7px;
        }
    }

    .snotifyToast__title {
        font-size: 1.5em;
    }
}
