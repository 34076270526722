.table {
    font-size: .85rem;
}

.list-action {
    font-size: 20px;
    padding: 5px 8px;
    margin: auto 2px;
    border-radius: 5px;
    border: 1px solid #8080804d;
}

// enable datatable's pagination inline with info on desktop view
@media screen and (min-width: 700px){
    .dataTables_info {
        float: left;
    }
}


// Dashboard Style
.product{
    display: flex;

    &-details, &-center{
        display: flex;
    }

    &-center{
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .sold{
            font-weight: 700;
            font-size: 1rem;
        }
    }

    &-title{
        .title{
            font-size: 1rem;
        }
    }
}

.order{
    &-container{}
    &-header, &-contents{
        display: flex;
        align-items: center;
        align-content: center;
        flex-direction: row;
        flex-grow: 0;
        flex-basis: min-content;

        .content{
            width: 100%;
            text-align: center;
            align-items: center;
            display: flex;
            padding: 10px;
            justify-content: center;
            column-gap: 10px;
        }
    }
    &-header{
        border-bottom: 1px solid #e3e3e3;
    }
    &-contents{
        border-bottom: 1px solid #e3e3e3;

        .content{

            &.customer{
                justify-content: flex-start;
                text-align: left;
            }

            &.price{
                flex-direction: column;
                .sold{
                    font-weight: 700;
                    font-size: 1rem;
                }
            }

           .customer-info{
               .name{
                   font-size: 1rem;
               }
           }

            .user-avatar{
                img{
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                }
            }

        }
    }
}

.reservation{
    &-header, &-contents{
        display: flex;
        align-items: center;
        align-content: center;
        flex-direction: row;
        flex-grow: 0;
        border-bottom: 1px solid #e3e3e3;

        .content{
            width: 100%;
            text-align: center;
            align-items: center;
            display: flex;
            padding: 10px;
            justify-content: center;
            column-gap: 10px;

            &.customer{
                display: flex;
                flex-direction: column;
                column-gap: 10px;
            }
        }
    }
}

// Order Details

%invoice-column-style{
    border-bottom: 1px solid #e3e3e3;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:last-of-type{
     border-bottom-color: transparent;
    }
}

.item{
    &-summery{
        display: flex;
        flex-direction: column;
        row-gap: 25px;

        &-header{
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: space-between;
        }

        &-content{
            display: flex;
            flex-direction: column;

            .content{
                @extend %invoice-column-style;
            }
        }

        &-footer{
            display: flex;
            flex-direction: column;

            .billing-item{
                @extend %invoice-column-style;
            }
        }
    }
}


.accordion-header{
    background-color: #e3e3e3;
}

.accordion-body{
    margin-top: 15px;
}

#toggleIcons{
    margin-top: 20px;
}

.accordion-container.customer{
    display: none;
}

@media screen and (max-width: 600px) {
    .content{
        .user-avatar{
            display: none;
        }
        &.requested-time, &.status{
            display: none;
        }
    }
    .reservation{
        .requested-for {
            display: none;
        }
    }
}

.vue-treeselect__single-value{
    text-transform: capitalize;
}

@media screen and (max-width: 800px){
    .accordion-container.customer{
        display: block;
    }
    .pc-only{
        display: none;
    }

    .modal.show .modal-dialog{
        //transform: translate(0,-50px);
    }
    .item-summery-header{
        flex-direction: column;
    }
}

.cursor-pointer{
    cursor: pointer;
}

.fa-8x {
    font-size: 8rem;
}

.page-action-btn-container {
    display: flex;
}

.page-wrapper .sidebar-wrapper .sidebar-menu ul li a .menu-text {
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-shrink: 1;
    overflow: hidden;
    font-size: 16px;
    font-weight: 600;
}

.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul li a {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 11px 35px 11px 35px;
    font-size: 14px;
    font-weight: 600;
}

.page-wrapper .sidebar-wrapper .sidebar-menu ul li.active .sidebar-submenu {
    display: block;
    background-color: #f3f3f3;
}





