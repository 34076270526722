.btn-outline-primary {
    border: 1px solid $primary;
}

.btn-outline-secondary {
    border: 1px solid $secondary;
}

.btn-outline-info {
    border: 1px solid $info;
}

.btn-outline-warning {
    border: 1px solid $warning;
}

.btn-outline-danger {
    border: 1px solid $danger;
}

.btn-outline-success {
    border: 1px solid $success;
}

.btn-outline-dark {
    border: 1px solid $dark;
}

.btn-outline-light {
    border: 1px solid $light;
}

