// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');



// Variables
@import './variables';

// utils
@import "./utility/util";

// Bootstrap
@import '../css/bootstrap.min.css';

@import "../fonts/style.css";
@import "../css/main.css";
@import "../css/chat.css";

// Plugins
@import "~vue-snotify/styles/simple";
@import '~@riophae/vue-treeselect/dist/vue-treeselect.css';

// Utilities
@import "./utility/card";
@import "./utility/notification";
@import "./utility/button";
@import "./custom";

// datatables
@import "../css/plugins/datatables/dataTables.bs4.css";
@import "../css/plugins/datatables/dataTables.bs4-custom.css";
@import "../css/plugins/datatables/buttons.bs.css";
